import { isDefined } from "@clipboard-health/util-ts";

import {
  ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH,
  DocumentRequirementType,
  PROFESSIONAL_REFERENCE_REQUIREMENT_ID,
} from "../Documents";
import { PROFESSIONAL_REFERENCE_URL_APP_V1 } from "../Documents/constants";

interface GenerateDocumentDetailsPath {
  hcfId?: string;
  requirement?: string;
  requirementStatus?: DocumentRequirementType;
  qualification?: string;
}

export function generateDocumentDetailsPath(props: GenerateDocumentDetailsPath) {
  const { hcfId, requirement, requirementStatus, qualification } = props;

  const shouldRouteToProfessionalReference =
    requirement === PROFESSIONAL_REFERENCE_REQUIREMENT_ID &&
    requirementStatus !== DocumentRequirementType.COMPLETED;
  if (shouldRouteToProfessionalReference) {
    return PROFESSIONAL_REFERENCE_URL_APP_V1;
  }

  const queryParams = new URLSearchParams({
    ...(isDefined(hcfId) && { hcfId }),
    ...(isDefined(requirement) && { requirement }),
    ...(isDefined(requirementStatus) && { requirementStatus }),
    ...(isDefined(qualification) && { qualification }),
  }).toString();

  if (queryParams === "") {
    return ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH;
  }

  return `${ACCOUNTS_DOCUMENTS_DETAILS_FULL_PATH}?${queryParams}`;
}
